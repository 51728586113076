/* .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: white;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

body {
  padding-top: 80px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 50px;
}

.nav-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.nav ul {
  display: flex;
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.nav a:hover {
  color: red;
}

@media (max-width: 1024px) {
  .header {
    padding: 15px;
  }
  .nav ul {
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 10px 15px;
  }

  .nav-container {
    gap: 20px;
  }

  .nav ul {
    gap: 10px;
  }

  .logo-img {
    height: 40px;
  }
}

@media (max-width: 576px) {
  .header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
  }

  .nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .nav a {
    font-size: 14px;
  }

  .logo-img {
    height: 35px;
  }
} */



.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: white;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

body {
  padding-top: 80px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 50px;
}

.nav-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.nav ul {
  display: flex;
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.nav a:hover {
  color: red;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 10px;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    padding: 10px;
  }

  .nav.open {
    display: flex;
  }

  .nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .menu-toggle {
    display: block;
    background-color: red;
  }
}

