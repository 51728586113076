.talent-section {
  text-align: center;
  padding: 10px 10px;
}

.talent-section h2 {
  font-size: 2rem;
  font-weight: bold;
  color: red !important;
  margin: 0px;
}

.carousel-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  background: white;
  padding: 10px 0;
}

.carousel-track {
  display: flex;
  width: calc(160px * 28);
  animation: scroll 15s linear infinite;
}

.logo-item {
  flex: 0 0 auto;
  width: 100px;
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-item img {
  width: 100%;
  max-width: 120px;
  height: auto;
  object-fit: contain;
}

/* Continuous Scrolling Animation */
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

/* ✅ Responsive Styling */
@media (max-width: 1024px) {
  .carousel-track {
    animation: scroll 20s linear infinite;
  }
}

@media (max-width: 768px) {
  .logo-item {
    width: 80px;
    margin-right: 30px;
  }

  .logo-item img {
    max-width: 100px;
    height: 150px;
  }

  .carousel-track {
    animation: scroll 25s linear infinite;
  }
}

@media (max-width: 576px) {
  .logo-item {
    width: 70px;
    margin-right: 20px;
  }

  .logo-item img {
    max-width: 90px;
    height: 120px;
  }

  .carousel-track {
    animation: scroll 30s linear infinite;
  }
}
