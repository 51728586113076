/* .footer {
    background-color: black;
    color: white;
    text-align: center;
    padding-top: 20px;
    gap: 10px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.footer-section {
    flex: 1;
    min-width: 250px;
    text-align: left;
    padding: 15px;
} */

/* .footer-logo-container {
    align-items: center;
    justify-content: center;
}

.footer-logo-img {
    height: 50px;
    margin-bottom: 10px;
} */

/* .footer-section p {
    margin-top: 10px;
    font-size: 14px;
} */

/* .footer-title {
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 50px;
}

.footer-links1 {
    list-style: none;
    padding: 0;
}

.footer-links1 li {
    margin-top: 8px;
} */

/* .footer-links {
    list-style: none;
    padding: 0;
    margin-left: 50px;
}

.footer-links li {
    margin-bottom: 8px;
}

.footer-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
} */

/* .footer-links a:hover {
    color: red;
}

.contact-info {
    margin-right: 50px;
}

.contact-info .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
} */

/* .contact-info .icon {
    margin-right: 10px;
    font-size: 20px;
}

.contact-info .map-icon {
    margin-right: 10px;
    font-size: 50px;
} */

/* .social-icons {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
}

.social-icons a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #333;
    color: white;
    font-size: 20px;
    margin: 5px;
    border-radius: 50%;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.social-icons-face {
    background-color: #1877f2 !important;
}

.social-icons-you {
    background-color: red !important;
}

.social-icons-insta {
    background-color: #d62976 !important;
} */









/* Footer Bottom */
.footer-bottom {
    background-color: #222;
    padding: 15px;
    margin-top: 20px;
    font-size: 14px;
}

.footer-bottom a {
    color: white;
    text-decoration: none;
}

.footer-bottom a:hover {
    color: red;
}



.footer {
    background-color: black;
    color: white;
    text-align: center;
    padding: 0px 0;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .footer-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-column {
    flex: 1;
    min-width: 250px;
    text-align: left;
    padding: 15px;
  }
  
  .footer-logo-img {
    height: 50px;
    margin-bottom: 10px;
  }
  
  .footer-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .footer-links1,
  .footer-links {
    list-style: none;
    padding: 0;
  }

  .footer-links1 li{
    margin-top: 5px;
  }
  
  .footer-links li {
    margin-bottom: 8px;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: red;
  }
  
  .contact-info .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .contact-info .icon {
    margin-right: 10px;
    font-size: 20px;
  }
  
  .contact-info .map-icon {
    margin-right: 10px;
    font-size: 50px;
  }
  
  .social-icons {
    display: flex;
    justify-content: left;
    margin-top: 20px;
  }
  
  .social-icons a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #333;
    color: white;
    font-size: 20px;
    margin: 5px;
    border-radius: 50%;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .social-icons-face {
    background-color: #1877f2 !important;
  }
  
  .social-icons-you {
    background-color: red !important;
  }
  
  .social-icons-insta {
    background-color: #d62976 !important;
  }

