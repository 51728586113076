.blog-section {
    text-align: center;
    padding: 10px;
}

.blog-section h2 {
    font-size: 2rem;
    font-weight: bold;
    color: red;
    margin-bottom: 30px;
}

.blog-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.blog-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 350px;
    height: 500px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.blog-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    min-height: 200px;
    /* Ensure uniform content height */
}

.blog-content h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #223;
    min-height: 60px;
    /* Ensure all titles have the same height */
}

.blog-content p {
    color: #555;
    font-size: 0.95rem;
    margin: 10px 0;
    flex-grow: 1;
    /* Makes content expand evenly */
    min-height: 80px;
    /* Fix paragraph height */
}

.blog-content a {
    color: red;
    font-weight: bold;
    text-decoration: none;
    align-self: flex-start;
    margin-top: auto; /* Pushes the button to the bottom */
    display: block;
    position: relative;
}

.blog-content a:hover {
    text-decoration: underline;
}




/* Owl Carousel Custom Styling */
.owl-theme .owl-nav {
    margin-top: 10px;
}

.owl-theme .owl-nav [class*="owl-"] {
    background: #222;
    color: white;
    padding: 5px 12px;
    font-size: 18px;
    margin: 5px;
    border-radius: 50%;
    transition: 0.3s;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
    background: red;
}

.owl-theme .owl-dots .owl-dot span {
    background: #bbb;
    width: 12px;
    height: 12px;
    margin: 5px;
    border-radius: 50%;
    transition: 0.3s;
}

.owl-theme .owl-dots .owl-dot.active span {
    background: red;
}