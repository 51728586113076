.sectors {
    text-align: center;
    padding: 30px 10px;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    color: red;
    margin-bottom: 20px;
}

.sector-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.sector-card {
    width: 100%;
    max-width: 380px;
    height: 350px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.button-overlay {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: red;
    padding: 10px 20px;
    border: none;
    font-size: 16px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    width: 200px;
}

.button-overlay:hover {
    background-color: darkred;
}

/* Info Section */
.info-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
    background-color: #fff;
    flex-wrap: wrap;
}

.info-container {
    display: flex;
    align-items: center;
    max-width: 1100px;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
}

.info-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border: none;
}

.info-content {
    flex: 1;
    text-align: center;
    max-width: 500px;
}

.info-title {
    font-size: 2rem;
    font-weight: bold;
    color: red;
    margin-top: -10px;
}

.info-text {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    margin-top: 15px;
    text-align: justify;

}

.info-button {
    background-color: red;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    width: 160px;
}

.info-button:hover {
    background-color: darkred;
}

/* Section Container */
.section-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    flex-wrap: wrap;
    text-align: center;
}

.text-container h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
}

.image-container img {
    width: 100%;
    max-width: 800px;
    height: auto;
    border: none;
}

/* Blog Section
.blog-section {
    text-align: center;
    padding: 10px;
}

.blog-section h2 {
    font-size: 2rem;
    font-weight: bold;
    color: red;
    margin-bottom: 30px;
}

.blog-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.blog-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 320px;
    height: 500px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.blog-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.blog-content h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #223;
}

.blog-content p {
    color: #555;
    font-size: 0.95rem;
    margin: 10px 0;
    flex-grow: 1;
}

.blog-content a {
    color: red;
    font-weight: bold;
    text-decoration: none;
    align-self: flex-start;
}

.blog-content a:hover {
    text-decoration: underline;
} */

/* Stats Section */
.stats-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    background: #004466;
    color: white;
    padding: 20px 10px;
    text-align: center;
}

.stat-item {
    min-width: 100px;
    margin: 2px;
    flex: 0 1 270px;
}

.stat-section h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 5px 0;
}

.stat-item p {
    font-size: 18px;
    margin: 0;
}

.stat-value {
    font-size: 24px;
    font-weight: bold;
}

.achivement-heading {
    font-size: 2rem;
    font-weight: bold;
    color: red;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

/* ✅ Responsive Design */
@media (max-width: 1024px) {
    .info-title {
        font-size: 1.8rem;
    }

    .info-text {
        font-size: 0.95rem;
    }

    .blog-card {
        height: auto;
    }
}

@media (max-width: 768px) {
    .sector-container {
        flex-direction: column;
        align-items: center;
    }

    .info-container {
        flex-direction: column;
        text-align: center;
    }

    .section-container {
        flex-direction: column;
        text-align: center;
        padding: 20px;
    }

    .text-container h1 {
        font-size: 2rem;
    }

    .blog-card {
        max-width: 100%;
    }
}

@media (max-width: 576px) {
    .info-title {
        font-size: 1.6rem;
    }

    .info-text {
        font-size: 0.9rem;
    }

    .info-button {
        font-size: 0.9rem;
        padding: 8px 14px;
    }

    .stat-item {
        flex: 1 1 100%;
    }

    .blog-card {
        width: 100%;
    }
}