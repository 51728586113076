.services-section {
    /* background-color: #f4f4f4; */
    text-align: center;
    padding: 10px 20px;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    /* color: #002b40; */
    color: red;
  }
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    max-width: 1200px;
    margin: auto;
  }
  
  .services-box {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px;
  }
  
  .services-box h3 {
    font-size: 22px;
    color: #004466;
    margin-bottom: 15px;
  }
  
  .services-box ul {
    list-style: none;
    padding: 0;
  }
  
  .services-box li {
    font-size: 15px;
    color: #333;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }
  
  .services-box-icon {
    color: #004466;
    font-size: 20px;
  }
  