.banner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.banner-section {
  position: relative;
  width: 50%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.job-seeker {
  background: url("../../images/job-seeker.jpg") center/cover no-repeat;
  height: 600px;
}

.employer {
  background: url("../../images/employer.jpg") center/cover no-repeat;
  height: 600px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(224, 30, 38, 0.5);
}

.employer .overlay {
  background-color: rgba(78, 110, 168, 0.5);
}

.content {
  position: relative;
  z-index: 2;
  padding: 20px;
}

.content p {
  font-size: 24px;
}

.content h1 {
  font-size: 50px;
  font-weight: bold;
  margin: 10px 0;
}

.apply-btn {
  background: red;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s ease-in-out;
}

.apply-btn:hover {
  background: darkred;
}

/* ✅ Responsive Design */
@media (max-width: 1024px) {
  .content h1 {
    font-size: 40px;
  }

  .content p {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .banner {
    flex-direction: column;
  }

  .banner-section {
    width: 100%;
    height: 450px;
  }

  .content h1 {
    font-size: 35px;
  }

  .content p {
    font-size: 20px;
  }

  .apply-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .banner-section {
    height: 400px;
  }

  .content h1 {
    font-size: 30px;
  }

  .content p {
    font-size: 18px;
  }

  .apply-btn {
    font-size: 14px;
    padding: 8px 14px;
  }
}
