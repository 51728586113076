
/* Default Styling */
.testimonials-container {
  text-align: center;
  margin-bottom: 30px;
}
.testimonials-heading {
  font-weight: bold;
  margin-bottom: 20px;
  color: red;
}

.testimonial-card {
  background: #FAF9F6;
  /* background: #004466; */
  padding: 20px 15px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  width: 80%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin: 15px auto;
  /* Centers the card */
}

.testimonial-text {
  font-size: 16px;
  font-weight: 500;
  /* color: #333; */
  color: #343434;
  text-align: justify;
  max-width: 80%;
}

.stars {
  font-size: 24px;
  color: gold;
  text-align: center;
  margin-top: 0px;
}

.slick-dots {
  bottom: -40px !important;
}

/* Slick Dots */
.slick-dots li button:before {
  font-size: 12px;
  color: gray;
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 1;
}

/* ======= RESPONSIVE DESIGN ======= */

/* Tablets (max-width: 1024px) */
@media (max-width: 1024px) {
  .testimonial-card {
    width: 90%;
    /* Slightly larger width for medium screens */
    height: auto;
    /* Adjust height dynamically */
    padding: 25px;
  }

  .testimonial-text {
    font-size: 15px;
    max-width: 90%;
  }
}

/* Mobile Devices (max-width: 768px) */
@media (max-width: 768px) {
  .testimonials-container {
    padding: 30px 15px;
  }

  .testimonial-card {
    width: 100%;
    /* Full width for smaller devices */
    height: auto;
    /* Adjust height dynamically */
    padding: 20px;
    text-align: center;
  }

  .testimonial-text {
    font-size: 14px;
    text-align: center;
    /* Center text on small devices */
  }

  .stars {
    font-size: 20px;
  }

  .slick-dots li button:before {
    font-size: 10px;
  }
}

/* Extra Small Devices (iPhone SE, max-width: 480px) */
@media (max-width: 480px) {
  .testimonial-card {
    padding: 15px;
  }

  .testimonial-text {
    font-size: 13px;
    max-width: 95%;
  }

  .stars {
    font-size: 18px;
  }

  .slick-dots li button:before {
    font-size: 8px;
  }
}