.training-section-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
}

.training-background-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url("../../images/trainingBack.jpg") center/cover no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    padding: 20px;
}

.text-box {
    color: black;
    font-size: 1.7rem;
    font-weight: bold;
    position: relative;
    z-index: 1;
    margin-left: 30px;
}


.training-placement {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #012E44;
    color: white;
    padding: 40px;
}

.developing-content {
    width: 45%;
    font-size: 18px;
    line-height: 1.6;
    max-width: 550px;
    justify-content: flex-end;
    text-align: justify;


}

.shapes {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.shape-image {
    max-width: 80%;
    height: auto;
}





.training-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(to bottom, #f0f0f0, #d3d3d3); */
    background: #d3d3d3;
    padding: 40px 0px;
}

.training-content {
    display: flex;
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.training-left {
    flex: 1;
}

.training-right {
    flex: 1;
    padding: 40px;
    text-align: left;
}

.training-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.training-text {
    font-size: 18px;
    color: #333;
    line-height: 1.5;
    text-align: center;
}

.training-image {
    width: 80%;
    height: auto;
    max-width: 400px;
    max-height: 400px;
}





.training-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(to bottom, #f0f0f0, #d3d3d3); */
    background: #d3d3d3;
    padding: 0px 0px;
}

.training-content1 {
    display: flex;
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.training-left1 {
    flex: 1;
}

.training-right1 {
    flex: 1;
    padding: 40px;
    text-align: left;
}

.training-title1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.training-text1 {
    font-size: 18px;
    color: #333;
    line-height: 1.5;
    text-align: center;
}

.training-image1 {
    width: 80%;
    height: auto;
    max-width: 400px;
    max-height: 400px;
}


.sub-images {
    display: flex;
    gap: 10px;
    justify-content: center;
    background: #d3d3d3;
    padding: 50px;
}

.small-image {
    width: 200px;
    height: 200px;
}

.communication-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #0b2a3c; */
    background-color: #012E44;
    color: white;
    padding: 50px;
}

.communication-content {
    max-width: 80%;
    margin-left: 20px;
}

.communication-content h2 {
    font-size: 26px;
    font-weight: bold;
}

.communication-content p {
    font-size: 16px;
    margin-bottom: 20px;
}

.communication-circle {
    width: 300px;
    height: 300px;
    /* background-color: rgba(255, 255, 255, 0.1); */
    background-color: #1e5671;
    border-radius: 50%;
    margin-right: 20px;
}





.consulting-section {
    display: flex;
    gap: 20px;
    padding: 40px;
    background-color: #f5f5f5;
}

.consulting-left {
    flex: 2;
}

.consulting-large-image {
    width: 100%;
    height: auto;
    /* border-radius: 10px; */
}

.consulting-right {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.consulting-text-box {
    background-color: white;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    /* border-radius: 10px; */
}

.consulting-text-box h2 {
    font-size: 22px;
    font-weight: bold;
}

.consulting-text-box p {
    font-size: 16px;
    color: #333;
}

.consulting-images {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.consulting-small-image {
    width: 48%;
    /* border-radius: 10px; */
}



.benefits-section {
    max-width: 1000px;
    margin: 40px auto;
    padding: 20px;
    text-align: left;
}

.benefits-section h2 {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.benefits-section ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-left: 100px;
}

.benefits-section li {
    font-size: 20px;
    margin-bottom: 10px;
}


.training-section {
    background-color: #002b40;
    color: white;
    padding: 50px 20px;
}

.training-section-container {
    display: flex;
    max-width: 1200px;
    margin: auto;
    align-items: center;
    gap: 30px;
}

/* Left Image Section */
.training-container-image-container {
    flex: 1;
    max-width: 50%;
}

.training-container-image-container img {
    width: 100%;
    border-radius: 10px;
}

/* Right Cards Section */
.training-container-cards-container {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 2x2 Grid */
    gap: 20px;
    margin-right: 20px;
}

.training-container-card {
    background-color: white;
    color: black;
    padding: 20px;
    /* border-radius: 8px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left;
}

.training-container-card h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.section-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
}


.training-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    background-color: #ffffff;
}

.training-box {
    display: flex;
    align-items: center;
    max-width: 1200px;
    gap: 50px;
}

.training-photo {
    position: relative;
    width: 650px;
}

.training-photo img {
    width: 100%;
    /* border-radius: 8px; */
}


.training-details {
    max-width: 550px;
    position: relative;
}

.training-details h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 15px;
}

.training-details p {
    font-size: 16px;
    color: #444;
    line-height: 2;
}




@media (max-width: 1024px) {
    .training-background-container {
      height: 70vh;
    }
  
    .text-box {
      font-size: 1.5rem;
    }
  
    .training-placement, .communication-section {
      flex-direction: column;
      text-align: center;
      padding: 30px;
    }
  
    .developing-content, .shapes, .communication-content {
      width: 100%;
    }
  
    .training-container,
    .training-container1,
    .training-wrapper,
    .training-section-container {
      flex-direction: column;
      text-align: center;
      padding: 20px;
    }
  
    .training-photo {
      width: 100%;
    }
  
    .training-details {
      max-width: 100%;
      padding: 20px;
    }
  
    .training-container-cards-container {
      grid-template-columns: 1fr;
      margin-right: 0;
    }
  
    .consulting-section {
      flex-direction: column;
      text-align: center;
    }
  }
  
  @media (max-width: 768px) {
    .training-background-container {
      height: 60vh;
    }
  
    .text-box {
      font-size: 1.3rem;
      margin-left: 10px;
    }
  
    .training-content,
    .training-content1 {
      flex-direction: column;
      text-align: center;
    }
  
    .training-left, .training-right,
    .training-left1, .training-right1 {
      width: 100%;
      padding: 20px;
    }
  
    .training-image, .training-image1 {
      width: 100%;
      max-width: 300px;
      height: auto;
      margin: auto;
    }
  
    .sub-images {
      flex-direction: column;
      align-items: center;
    }
  
    .small-image {
      width: 150px;
      height: 150px;
    }
  
    .consulting-images {
      flex-direction: column;
    }
  
    .benefits-section ul {
      margin-left: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .training-background-container {
      height: 50vh;
      padding: 10px;
    }
  
    .text-box {
      font-size: 1.1rem;
      margin-left: 5px;
    }
  
    .training-placement,
    .communication-section,
    .consulting-section {
      padding: 20px;
    }
  
    .training-container-cards-container {
      grid-template-columns: 1fr;
    }
  
    .training-box {
      flex-direction: column;
      gap: 20px;
    }
  
    .training-photo {
      width: 100%;
    }
  
    .communication-circle {
      width: 200px;
      height: 200px;
      margin: auto;
    }
  }
  