.contact-us-section {
    position: relative;
    width: 100%;
    height: 250px;
    background: url('../../images/contactMap.jpg');
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left !important;
}

.contact-title {
    font-size: 48px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    margin-left: 50px;
}

.contact-form-container {
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    height: 100px;
  }
  
  button {
    background-color: #d80000;
    color: white;
    padding: 10px;
    margin-top: 15px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    width: 130px;
    align-self: center;
  }
  
  button:hover {
    background-color: #b00000;
  }
  
